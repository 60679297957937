<template>
  <div class="main-body">
    <div>
      <h1 class="title">
        {{ newsform.title }}
      </h1>
      <div class="time">
        <span>发布时间:&nbsp;&nbsp;{{ newsform.publishTime }}</span>
        <span style="margin-left: 20px"
          >来源:&nbsp;&nbsp;{{ newsform.source }}</span
        >
        <span style="margin-left: 20px"
          >浏览次数:&nbsp;&nbsp;{{ newsform.browserNum }}</span
        >
      </div>
      <div class="line"></div>
      <!-- <div class="picture">
        <img
          :src="
            require('@/' + Newsdata.datalist[this.$route.query.id - 1].image)
          "
        />
      </div> -->
      <div class="ql-editor" v-html="newsform.content"></div>
      <div class="explain" v-if="newsform.source === '网络'">
        本文来自网络，如有侵权，请联系删除。
      </div>
    </div>
  </div>
</template>
<script>
import Resultjson from '../../../public/json/news.json';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { get, post, rowpost } from '@/utils/request.js';
export default {
  data() {
    return {
      Newsdata: Resultjson.data,
      newsform: '',
    };
  },
  created() {
    this.getFormData();
  },
  mounted() {},
  methods: {
    getFormData() {
      let uuid = this.$route.query.id;
      let params = {
        uuid: uuid,
      };
      get('/news/getNewsById', params).then((res) => {
        this.newsform = res.data;
      });
    },
  },
};
</script>
<style scoped lang="less">
.main-body {
  padding-top: 100px;

  > div {
    width: 1200px;
    margin: 0 auto;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
  }

  .time {
    color: #999999;
    font-size: 12px;
    line-height: 35px;
    text-align: center;
  }

  .line {
    height: 1px;
    width: 100%;
    background: rgba(221, 221, 221, 1);
    margin: 10px 0;
  }
  .picture {
    text-align: center;
    max-width: 100%;
  }
  .explain {
    padding: 12px 15px;
  }
}

::v-deep.ql-editor {
  span {
    line-height: 30px;
  }

  p {
    margin-bottom: 10px;
    text-indent: 2em;
  }
  h3 {
    margin-top: 30px;
    text-indent: 2em;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
}
</style>
